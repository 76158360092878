import swal from '@sweetalert/with-react';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import EndBreakoutRoom from '../../common/Messages/EndBreakoutRoom';
import JoinBreakoutRoom from '../../common/Messages/JoinBreakoutRoom';
import { setStudioId, setToken } from '../../services/api';
import { store } from '../../store';
import { isIpad } from '../../utils/browser-util';
import Logger from '../../utils/logger';
import MediaDeviceUtil from '../../utils/media-device-util';
import SocketClient from '../../utils/socket-client';
import { EVENT_SUPPORT, OFF_WALL_ADMIN } from '../../utils/user-util';
import API from '../janusBreakoutRoom/API';
import Chat from '../janusBreakoutRoom/chat/Chat';
import CollapseButton from '../janusBreakoutRoom/CollapseButton';
import GridView from './GridView';
import LocalParticipant from './LocalParticipant';
import ParticipantList from './ParticipantList';
import ScreenShareVideo from './ScreenShareVideo';
import './TwilioBreakoutRoom.css';
import useTwilioRoom from './useTwilioRoom';

const mlogger = new Logger('twilioBreakoutRoom:TwilioBreakoutRoom');

export default function TwilioBreakoutRoom({ loadTestStream, onBreakoutRoomStop }) {
  const {
    room,
    connect,
    localParticipant,
    participants,
    videoEnabled,
    audioEnabled,
    toggleAudio,
    toggleVideo,
    toggleScreenShare,
    screenshareTrack,
    screenShareEnabled,
    resetState,
  } = useTwilioRoom();

  const twilioRoomRef = useRef(null);

  useEffect(() => {
    if (room) {
      twilioRoomRef.current = room;
    }
  }, [room]);

  const uuidRef = useRef(null);
  const sidRef = useRef(null);
  const roomRef = useRef(null);
  const credentialsRef = useRef(null);
  const [credentials, setCredentials] = useState({});
  const [startTime, setStartTime] = useState(null);
  const [identity, setIdentity] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [isHost, setIsHost] = useState(false);
  const [isPresenter, setIsPresenter] = useState(false);
  const [supportRequested, setSupportRequested] = useState(false);
  const [hasScreensharePermission, setHasScreensharePermission] = useState(false);
  const [isRemoteScreenSharing, setIsRemoteScreenSharing] = useState(false);
  const [remoteTracksState, setRemoteTracksState] = useState({});

  const [roomIndex, setRoomIndex] = useState(0);
  const [currentHost, setCurrentHost] = useState(null);
  const [currentPresenter, setCurrentPresenter] = useState(null);
  const [hideSideBar, setHideSideBar] = useState(false);
  const [showParticipantList, setShowParticipantList] = useState(true);
  const [hideAttendeeDetails, setHideAttendeeDetails] = useState(true);

  const screenshareVideoRef = useRef(null);

  const connectToTwilioRoom = async () => {
    if (!credentialsRef.current) return;
    const flogger = mlogger.deeper('connectToTwilioRoom', true);

    const { room, participant, uuid, twilioToken, twilioRoomName, startTime, identity, subStatus } = credentialsRef.current;
    setIdentity(identity);
    setRoomIndex(room.index);
    setIsHost(uuid === room.host);
    setStartTime(new Date(startTime));
    setSupportRequested(!!subStatus);
    setPhotoUrl(participant.photoUrl);
    setShowParticipantList(room.index <= 15 ? true : false);

    let mediaConstraints = {
      audio: true,
      video: { frameRate: 15 },
    };

    if (participant.selectedDevices) {
      flogger.info('Loading media devices');
      const availableDevices = await MediaDeviceUtil.getAvailableDevices();

      const { videoInput, audioInput } = participant.selectedDevices;

      const savedVideoDevice = localStorage.getItem('videoInput') && JSON.parse(localStorage.getItem('videoInput'));
      const savedAudioDevice = localStorage.getItem('audioInput') && JSON.parse(localStorage.getItem('audioInput'));
      flogger.info('Previous input devices:', { video: savedVideoDevice, audio: savedAudioDevice });

      const selectedVideoInput = MediaDeviceUtil.getSelectedDevice(availableDevices.filter(d => d.kind === 'videoinput'), savedVideoDevice);
      const selectedAudioInput = MediaDeviceUtil.getSelectedDevice(availableDevices.filter(d => d.kind === 'audioinput'), savedAudioDevice);
      flogger.info('Selected input devices:', { video: selectedVideoInput, audio: selectedAudioInput });

      if (selectedVideoInput) {
        mediaConstraints.video.deviceId = selectedVideoInput.deviceId;
        mediaConstraints.video.width = { ideal: videoInput.width };
        mediaConstraints.video.height = { ideal: videoInput.height };
      }
      if (selectedAudioInput) {
        mediaConstraints.audio = { deviceId: selectedAudioInput.deviceId };
      }
    }

    if (loadTestStream) {
      const tracks = loadTestStream.getTracks();
      mediaConstraints = {
        tracks,
      };
    }

    flogger.info('Connecting to room');
    connect({
      token: twilioToken,
      options: {
        name: twilioRoomName,
        bandwidthProfile: {
          video: {
            mode: 'grid',
          },
        },
        maxAudioBitrate: 16000,
        preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
        networkQuality: { local: 1, remote: 1 },
        ...mediaConstraints,
      },
    });

    flogger.info('Leaving socket room', roomRef.current._id);
    SocketClient.leaveRoom(roomRef.current._id);
    SocketClient.removeListener('message');
    SocketClient.removeListener('set-as-breakout-room-host');
    SocketClient.removeListener('remove-breakout-room-host');

    flogger.info('Rejoining socket room', roomRef.current._id);
    SocketClient.joinRoom(roomRef.current._id);
    SocketClient.addListener('message', (data) => {
      if (data.roomName === roomRef.current._id) {
        const logger = flogger.deeper('message', true);
        const { type } = data;
        if (type === 'make-presenter') {
          logger.info('received make-presenter', data);
          const { sid } = data.item;
          if (sidRef.current === sid) {
            setIsPresenter(true);
            setHasScreensharePermission(true);
          } else {
            setIsPresenter(false);
            setHasScreensharePermission(false);
          }
          setCurrentPresenter(data.item);
        } else if (type === 'make-participant') {
          logger.info('received make-participant', data);
          const { sid } = data.item;
          if (sidRef.current === sid && screenShareEnabled) {
            toggleScreenShare();
          }
          setCurrentPresenter(null);
          setIsPresenter(false);
          setHasScreensharePermission(false);
        } else if (type === 'set-current-host') {
          logger.info('received set-current-host', data);
          setCurrentHost(data.host);
        } else if (type === 'update-media-status') {
          logger.info('received update-media-status', data);
          const { photoUrl, participantSid, videoEnabled } = data.data;
          setRemoteTracksState((prevState) => ({ ...prevState, [participantSid]: { ...prevState[participantSid], photoUrl, video: videoEnabled } }));
        }
      }
    });

    SocketClient.addListener('set-as-breakout-room-host', () => {
      const logger = mlogger.deeper('set-as-breakout-room-host', true);
      logger.info('Setting host');
      setIsHost(true);
      SocketClient.emit('message', { roomName: roomRef.current._id, message: { type: 'set-current-host', host: sidRef.current } });
    });

    SocketClient.addListener('remove-breakout-room-host', () => {
      const logger = mlogger.deeper('remove-breakout-room-host', true);
      logger.info('Removing host');
      setIsHost(false);
      if (screenShareEnabled) {
        logger.info('Deactivating screen share');
        toggleScreenShare();
      }
      SocketClient.emit('message', { roomName: roomRef.current._id, message: { type: 'set-current-host', host: null } });
    });
  };

  useEffect(() => {
    const logger = mlogger.deeper('load', true);
    (async () => {
      try {
        if (!loadTestStream) {
          const {
            router: {
              location: { state: participantInfo },
            },
          } = store.getState();
          if (!participantInfo) {
            logger.error('No participant info found. Redirecting to login page.');
            const { origin, search } = window.location;
            const pathname = window.location.pathname.replace('/breakout-room', '/login');
            window.location.href = `${origin}${pathname}${search}`;
          }
        }

        const urlParams = new URLSearchParams(window.location.search);
        const studioId = urlParams.get('studioId');
        const accessToken = urlParams.get('accessToken');
        const eventId = urlParams.get('eventId');
        setStudioId(studioId);

        let result;
        if (loadTestStream) {
          logger.info('Getting token for test client');
          result = await API.getTokenForTestClient(eventId);
        } else {
          logger.info('Verifying token');
          result = await API.verifyToken(accessToken);
        }

        const { valid, credentials } = result;

        if (valid) {
          const { socketServerUrl, token: authToken, event, room, maxRooms, uuid, role } = credentials;
          uuidRef.current = uuid;
          roomRef.current = room;
          credentialsRef.current = credentials;
          setCredentials(credentials);
          setToken(authToken);
          setHideAttendeeDetails(event && event.hideAttendeeDetails && ![EVENT_SUPPORT, OFF_WALL_ADMIN].includes(role));

          const sendToStudioQueue = () => {
            const {
              router: {
                location: { state: participantInfo, search },
              },
            } = store.getState();
            const prevRoute = window.location.pathname;
            let searchParams = new URLSearchParams(search);
            searchParams.delete('accessToken');
            searchParams.delete('auto-connect');

            console.log('sendToStudioQueue', participantInfo, searchParams.toString());

            if (loadTestStream) {
              onBreakoutRoomStop && onBreakoutRoomStop();
            } else {
              store.dispatch(
                push({ pathname: '/connect', search: `${searchParams.toString()}&auto-connect=true`, state: { ...participantInfo, prevRoute, videoEnabled } }),
              );
            }
          };

          if (!SocketClient.socket || !SocketClient.socket.connected) {
            mlogger.info('socket server setup', socketServerUrl);
            await SocketClient.setup(socketServerUrl, uuidRef.current, event._id, studioId, authToken);
          }
          mlogger.info('Joining socket room', uuidRef.current);
          SocketClient.joinRoom(uuidRef.current);
          SocketClient.addListener('breakout-stop', async ({ wait }) => {
            const logger = mlogger.deeper('breakout-stop');
            logger.info('Notifying user and waiting...');
            await swal({
              buttons: {},
              className: isIpad ? 'swal-custom-content-ipad' : 'swal-custom-content',
              content: <EndBreakoutRoom wait={wait} />,
              closeOnClickOutside: false,
              closeOnEsc: false,
            });
            swal.close();
            logger.info('Done waiting');
            if (twilioRoomRef.current) {
              logger.info('Disconnecting from room');
              twilioRoomRef.current.disconnect();
            }
            resetState();
            sendToStudioQueue();
          });

          SocketClient.addListener('send-to-studio-queue', () => {
            const logger = mlogger.deeper('send-to-studio-queue', true);
            logger.info('Received send-to-studio-queue');
            if (twilioRoomRef.current) {
              logger.info('Disconnecting from room');
              twilioRoomRef.current.disconnect();
            }
            swal.close();
            logger.info('Resetting room state');
            resetState();
            logger.info('Sending to studio queue');
            sendToStudioQueue();
          });

          SocketClient.addListener('send-to-room', async () => {
            const logger = mlogger.deeper('send-to-room');
            logger.info('Received');
            if (twilioRoomRef.current) {
              logger.info('Disconnecting fom room');
              twilioRoomRef.current.disconnect();
            }
            logger.info('Cleaning state');
            resetState();
            setCurrentPresenter(null);
            setIsPresenter(false);
            setHasScreensharePermission(false);
            logger.info('Verifying token');
            const { valid, credentials } = await API.verifyToken(accessToken);
            if (valid) {
              logger.info('Valid token');
              const { uuid, room, maxRooms, event, role } = credentials;
              uuidRef.current = uuid;
              roomRef.current = room;
              credentialsRef.current = credentials;
              setCredentials(credentials);
              setHideAttendeeDetails(event && event.hideAttendeeDetails && ![EVENT_SUPPORT, OFF_WALL_ADMIN].includes(role));
              await swal({
                buttons: {},
                closeOnClickOutside: false,
                closeOnEsc: false,
                className: isIpad ? 'swal-custom-content-ipad' : 'swal-custom-content',
                content: (
                  <JoinBreakoutRoom
                    onClick={() => {
                      swal.close();
                      connectToTwilioRoom();
                    }}
                    roomName={room.index <= maxRooms ? `Breakout Room ${room.index}` : `Tech Support Room ${room.index - 15}`}
                  />
                ),
              });
            } else {
              logger.error('Invalid token!', accessToken);
            }
          });

          let accepted = Boolean(loadTestStream);
          if (!loadTestStream) {
            mlogger.info('Waiting user to accept join room');
            await swal({
              buttons: {},
              closeOnClickOutside: false,
              closeOnEsc: false,
              className: isIpad ? 'swal-custom-content-ipad' : 'swal-custom-content',
              content: (
                <JoinBreakoutRoom
                  onClick={() => {
                    accepted = true;
                    swal.close();
                  }}
                  roomName={room.index <= maxRooms ? `Breakout Room ${room.index}` : `Tech Support Room ${room.index - 15}`}
                />
              ),
            });
          }

          if (accepted) {
            mlogger.info('Connecting to twilio room');
            connectToTwilioRoom();
          }
        } else {
          logger.error('Invalid token', result);
        }
      } catch (error) {
        logger.error(error);
      }
    })();

    return () => {
      logger.info('Leaving room');
      SocketClient.leaveRoom(uuidRef.current);
      SocketClient.leaveRoom(roomRef.current._id);
      SocketClient.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    if (!room || !localParticipant) return;
    room.on('participantConnected', () => {
      if (!localParticipant) return;
      SocketClient.emit('message', {
        roomName: roomRef.current._id,
        message: { type: 'update-media-status', data: { videoEnabled, photoUrl, participantSid: localParticipant.sid } },
      });
    });
  }, [room, localParticipant]);

  useEffect(() => {
    if (localParticipant) {
      sidRef.current = localParticipant.sid;
      if (isHost) {
        SocketClient.emit('message', { roomName: roomRef.current._id, message: { type: 'set-current-host', host: sidRef.current } });
      }
    }
  }, [localParticipant, isHost]);

  useEffect(() => {
    if (!roomRef.current || !localParticipant) return;
    SocketClient.emit('message', {
      roomName: roomRef.current._id,
      message: { type: 'update-media-status', data: { videoEnabled, photoUrl, participantSid: localParticipant.sid } },
    });
  }, [videoEnabled, photoUrl, localParticipant]);

  useEffect(() => {
    if (screenShareEnabled && screenshareTrack) {
      screenshareTrack.attach(screenshareVideoRef.current);
      screenshareVideoRef.current.parentElement.style.display = 'block';
    } else {
      screenshareVideoRef.current.parentElement.style.display = 'none';
    }
  }, [screenshareTrack, screenShareEnabled]);

  const handleRemoteScreenshareTrack = useCallback(
    (track) => {
      if (track) {
        track.attach(screenshareVideoRef.current);
        screenshareVideoRef.current.parentElement.style.display = 'block';
        setIsRemoteScreenSharing(true);
      } else {
        screenshareVideoRef.current.parentElement.style.display = 'none';
        setIsRemoteScreenSharing(false);
      }
    },
    [screenshareVideoRef],
  );

  const handleVideoTrackState = useCallback(({ participant, state }) => {
    console.log('handleVideoTrackState', participant, state);
    setRemoteTracksState((prevState) => ({ ...prevState, [participant]: { ...prevState[participant], video: state } }));
  }, []);

  const handleAudioTrackState = useCallback(({ participant, state }) => {
    setRemoteTracksState((prevState) => ({ ...prevState, [participant]: { ...prevState[participant], audio: state } }));
  }, []);

  const requestSupport = useCallback(async () => {
    const logger = mlogger.deeper('requestSupport', true);
    const nextValue = !supportRequested;
    setSupportRequested(nextValue);
    const { uuid, event } = credentials;
    logger.info('Requesting support');
    await API.requestSupport(uuid, event._id, nextValue);
  }, [credentials, supportRequested]);

  const isTechSupportRoom = roomIndex > 15;
  const showSharedContent = screenShareEnabled || isRemoteScreenSharing;

  return (
    <div className='twilio-breakout-room-container'>
      <div className={hideSideBar ? 'left-panel collapsed' : 'left-panel'}>
        <CollapseButton value={hideSideBar} onClick={() => setHideSideBar(!hideSideBar)} style={{ left: 305 }} />
        <LocalParticipant
          localParticipant={localParticipant}
          identity={identity}
          photoUrl={photoUrl}
          isHost={isHost}
          isPresenter={isPresenter}
          hasScreensharePermission={hasScreensharePermission}
          currentHost={currentHost}
          currentPresenter={currentPresenter}
          roomIndex={roomIndex}
          audioEnabled={audioEnabled}
          videoEnabled={videoEnabled}
          screenShareEnabled={screenShareEnabled}
          isRemoteScreenSharing={isRemoteScreenSharing}
          toggleAudio={toggleAudio}
          toggleVideo={toggleVideo}
          toggleScreenShare={toggleScreenShare}
          isTechSupportRoom={isTechSupportRoom}
          onRequestSupport={requestSupport}
          supportRequested={supportRequested}
          startTime={startTime}
          showParticipantList={showParticipantList}
          onToggleParticipantList={() => setShowParticipantList(!showParticipantList)}
          hideAttendeeDetails={hideAttendeeDetails}
        />
        <ParticipantList
          participants={participants}
          remoteTracksState={remoteTracksState}
          isHost={isHost}
          isPresenter={isPresenter}
          currentHost={currentHost}
          currentPresenter={currentPresenter}
          hideAttendeeDetails={hideAttendeeDetails}
          onMakePresenter={(remoteParticipant) => {
            setCurrentPresenter(remoteParticipant.sid);
            SocketClient.emit('message', { roomName: roomRef.current._id, message: { type: 'make-presenter', item: remoteParticipant } });
          }}
          onMakeParticipant={(remoteParticipant) => {
            setCurrentPresenter(null);
            SocketClient.emit('message', { roomName: roomRef.current._id, message: { type: 'make-participant', item: remoteParticipant } });
          }}
          style={{ display: showParticipantList ? 'block' : 'none' }}
        />
        {isTechSupportRoom ? (
          <div style={{ position: 'relative', height: '100%', display: showParticipantList ? 'none' : 'block' }}>
            <Chat credentials={credentials} hideAttendeeDetails={hideAttendeeDetails} />
          </div>
        ) : null}
      </div>
      <div className={hideSideBar ? 'main-area collapsed' : 'main-area'}>
        <ScreenShareVideo showSharedContent={showSharedContent} ref={screenshareVideoRef} />
        <GridView
          participants={participants}
          onRemoteScreenshareTrack={handleRemoteScreenshareTrack}
          onVideoTrackStateChange={handleVideoTrackState}
          onAudioTrackStateChange={handleAudioTrackState}
          remoteTracksState={remoteTracksState}
          hideSideBar={hideSideBar}
          hideAttendeeDetails={hideAttendeeDetails}
          showSharedContent={showSharedContent}
        />
      </div>
    </div>
  );
}
